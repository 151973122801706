<script>
  /**
   * Start off for a single entry in the trending question widget.
   * Can extend to single entry for warehouse updates like `HomeUpdateWidgets`
   */
  import { get } from 'vuex-pathify'
  import HomeModalContent from '@/components/home/HomeModalContent'

  export default {
    components: {
      HomeModalContent,
    },
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      /** expects a widget type and slug object. ex: {type: 'trending', slug: 'holiday-delivery'} */
      update: {
        type: Object,
        required: true,
        validator: (value) => ['trending', 'warehouse'].includes(value?.type) && value?.slug,
      },
      /** Replace time with string and hide date */
      overrideDate: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...get('config', ['trendingQuestions', 'warehouseUpdates']),
      widgetModalBgColor() {
        return 'dawn'
      },
      singleUpdate() {
        const { type, slug } = this.update
        let entry
        let result = {}
        if (type === 'trending' && this.trendingQuestions?.length > 0) {
          entry = this.findEntry(slug, this.trendingQuestions)
          result = { type, ...entry }
        } else if (type === 'warehouse' && this.warehouseUpdates?.length > 0) {
          entry = this.findEntry(slug, this.warehouseUpdates)
          result = { type, ...entry }
        }
        return result
      },
      singleUpdateExists() {
        return Boolean(Object.keys(this.singleUpdate).length)
      },
    },
    methods: {
      findEntry(slug, entries) {
        for (const entry of entries) {
          if (slug === entry.slug) {
            return entry
          }
        }
        return {}
      },
    },
  }
</script>

<template>
  <Portal v-if="singleUpdateExists" to="modal">
    <BaseModal
      size="sm-md"
      height="md"
      :open="open"
      :bg-color="widgetModalBgColor"
      hide-nav-on-mobile
      dismissible
      sticky-icon
      mobile-full-screen
      @dismiss="$emit('dismiss')"
    >
      <HomeModalContent
        :update="singleUpdate"
        :bg-color="widgetModalBgColor"
        :override-date="overrideDate"
      />
    </BaseModal>
  </Portal>
</template>
