<script>
  import { get, call } from 'vuex-pathify'
  import trapFocus from '@/helpers/trapFocus'
  import { events } from '@/helpers/gtm'
  import { MESSAGE_UNEXPECTED_ERROR } from '@/helpers/constants'
  import FavoriteToggle from '@/components/marketplace/FavoriteToggle'
  import ProductSlider from '@/components/marketplace/ProductSlider'
  import HomeWidgetSingleEntryModal from '@/components/home/HomeWidgetSingleEntryModal'
  import screen from '@/helpers/screen'

  export default {
    components: {
      FavoriteToggle,
      ProductSlider,
      HomeWidgetSingleEntryModal,
    },
    async beforeRouteUpdate(to, from, next) {
      if ((await this.ensureProductExists()) === false) {
        next({ name: 'Market' })
        return
      }

      this.addToRecentlyViewed(this.product)

      // `beforeRouteUpdate` is only triggered if `MarketProduct` is reused,
      // which only happens on related product clicks. Might be too hacky.

      // Reset some data and scroll to the top of the modal
      this.imageSliderActiveIndex = 0
      this.selectedVariantId = null
      this.quantity = 1
      this.addingToCartOrWaitlist = false
      this.addedToCartOrWaitlist = false

      clearTimeout(this.addedTimeout)
      this.addedTimeout = null
      this.errorMessage = null
      await this.ensureProductContainerRefExists()
      this.productContainerRef.scrollIntoView({
        behavior: 'smooth',
      })
      next()
    },
    props: {
      productId: {
        type: Number,
        required: true,
      },
      productSlug: {
        type: String,
        default: undefined,
      },
    },
    data() {
      return {
        imageSliderActiveIndex: 0,
        isFavorited: null,
        selectedVariantId: null,
        quantity: 1,
        addingToCartOrWaitlist: false,
        addedToCartOrWaitlist: false,
        addedTimeout: null,
        productContainerRef: null,
        errorMessage: null,
        trendingQuestionsModalVisible: false,
      }
    },
    computed: {
      ...get('marketplace', ['products', 'allTags', 'tagColors', 'tagTextColors', 'brands']),
      ...get('account', ['favorites', 'hasMarketAccess', 'isActiveSubscriber']),
      ...get('marketplaceCart', ['lineItems', 'isMaxInCart', 'variantCanAddRushShipping']),
      ...get('feature', ['isRushShippingEnabled']),
      screen,
      isCurrentlyFavorited() {
        return this.product && this.favorites.includes(this.product.id)
      },
      product() {
        return this.products.find((product) => product.id === this.productId)
      },
      relatedProducts() {
        return this.products.filter((product) =>
          (this.product?.relatedProductIds ?? []).includes(product.id)
        )
      },
      brandProducts() {
        return this.products.filter(
          (product) =>
            // same brand, but different product
            this.product?.brand === product.brand && this.product?.id !== product.id
        )
      },
      brandFilterId() {
        return this.brands.findIndex((brand) => brand === this.product?.brand)
      },
      defaultVariant() {
        if (!this.product) {
          return undefined
        }
        // If the defaultVariant is out of stock, return the first variant that isn't
        const defaultVariant = this.product.defaultVariant
        if (!defaultVariant.hasUnlimitedStock && defaultVariant.stock === 0) {
          const firstVariantWithStock = this.product.variants.find((variant) => {
            return variant.hasUnlimitedStock || variant.stock > 0
          })
          if (firstVariantWithStock) {
            return firstVariantWithStock
          }
        }
        return defaultVariant
      },
      selectedVariant() {
        if (!this.product) {
          return undefined
        }
        if (this.selectedVariantId) {
          const selectedVariant = this.product.variants.find(
            (variant) => variant.id === this.selectedVariantId
          )
          if (selectedVariant) {
            return selectedVariant
          }
        }
        return this.defaultVariant
      },
      inStock() {
        return (
          this.selectedVariant &&
          (this.selectedVariant.hasUnlimitedStock || this.selectedVariant.stock > 0)
        )
      },
      quantitySelectionOptions() {
        // Use the Variant's maxQuantity if it's set, otherwise default to 10 as the
        // ceiling. Cap the ceiling at 10 for any instance where maxQuantity is
        // greater than that.
        const ceiling = Math.min(this.selectedVariant.maxQuantity || 10, 10)

        const max =
          this.selectedVariant &&
          this.selectedVariant.stock < ceiling &&
          !this.selectedVariant.hasUnlimitedStock
            ? this.selectedVariant.stock
            : ceiling
        if (max <= 0) {
          return []
        }
        return [...Array(Math.min(max, this.maxQuantityForCart)).keys()].map((i) => i + 1)
      },
      savedAmount() {
        return this.product.retailValue - this.selectedVariant.price
      },
      percentOff() {
        if (!this.product) {
          return 0
        }
        return Math.round((this.savedAmount / this.product.retailValue) * 100)
      },
      sortedTags() {
        return this.allTags.filter((tag) => (this.product.tags ?? []).includes(tag.slug))
      },
      maxQuantityAvailable() {
        if (this.selectedVariant.hasUnlimitedStock) {
          return this.selectedVariant?.maxQuantity
        } else {
          // when hasUnlimitedStock is true, stock is 0
          return Math.min(this.selectedVariant?.stock, this.selectedVariant?.maxQuantity)
        }
      },
      maxQuantityForCart() {
        const cartItem = this.lineItems.find(
          (lineItem) => lineItem.variantId === this.selectedVariant?.id
        )
        if (cartItem && !this.selectedVariant?.hasUnlimitedStock) {
          if (!this.isMaxInCart(this.selectedVariant?.id)) {
            return this.maxQuantityAvailable - cartItem.quantity
          }
          return 0
        }
        return this.maxQuantityAvailable
      },
    },
    watch: {
      isCurrentlyFavorited: {
        immediate: true,
        handler() {
          this.isFavorited = this.isCurrentlyFavorited
        },
      },
      quantity() {
        this.errorMessage = null
      },
    },
    async created() {
      window.addEventListener('keydown', this.handleKeyDown)
      this.$store.set('general/increaseOpenModalCount')
      if ((await this.ensureProductExists()) === false) {
        this.closeModal()
        return
      }
      events.viewedProduct({
        product: this.product,
        variant: this.selectedVariant,
        storeType: 'seasonal',
      })
      this.addToRecentlyViewed(this.product)
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyDown)
      this.$store.set('general/decreaseOpenModalCount')
    },
    methods: {
      ...call('marketplace', {
        doSetFavorited: 'setFavorited',
        doAddToCart: 'addToCart',
      }),
      ...call('marketplaceCart', {
        doAddToWaitlist: 'addToWaitlist',
      }),
      ...call('account', ['addToRecentlyViewed']),
      closeModal() {
        if (this.$route.name === 'CartMarketProduct') {
          this.$navigate({ name: 'MarketplaceCart' })
        } else {
          this.$navigate(this.$store.get('marketplace/pathWithFilters'))
        }
      },
      async ensureProductExists() {
        await this.$store.dispatch('marketplace/ensureFresh')
        return this.product !== undefined
      },
      async ensureProductContainerRefExists() {
        await this.$waitForValue(() => this.productContainerRef)
      },
      handleKeyDown($event) {
        // If the escape key was pressed, close the modal
        if ($event.keyCode === 27) {
          this.closeModal()
          return
        }
        // Trap the focus inside the modal
        trapFocus($event, this.productContainerRef)
      },
      setFavorited(isFavorited) {
        this.isFavorited = isFavorited
        // Don't need to wait for this to return
        this.doSetFavorited({
          productId: this.product.id,
          isFavorited: isFavorited,
        })
      },
      async addToCart() {
        this.errorMessage = null
        this.addingToCartOrWaitlist = true
        clearTimeout(this.addedTimeout)
        try {
          await this.doAddToCart({
            variantId: this.selectedVariant.id,
            quantity: this.quantity,
          })
          this.addingToCartOrWaitlist = false
          this.addedToCartOrWaitlist = true

          this.addedTimeout = setTimeout(() => {
            this.addedToCartOrWaitlist = false
          }, 5000)
        } catch (error) {
          const errors = error?.data?.errors
          if (errors) {
            // First attempt to get the validation error.
            this.errorMessage = errors[Object.keys(errors)[0]]
          } else {
            // Otherwise default to the error message.
            this.errorMessage = error?.message || MESSAGE_UNEXPECTED_ERROR
          }
        } finally {
          this.addingToCartOrWaitlist = false
        }
      },
      async addToWaitlist() {
        clearTimeout(this.addedTimeout)
        await this.doAddToWaitlist({
          productId: this.product.id,
        })
        this.addedToCartOrWaitlist = true
        this.addedTimeout = setTimeout(() => {
          this.addedToCartOrWaitlist = false
        }, 5000)
      },
      goToBrand() {
        this.$store.set('marketplace/filters', {
          brands: [this.brandFilterId.toString()],
        })
        this.$router.replace(this.$store.get('marketplace/pathWithFilters'))
      },
    },
  }
</script>

<template>
  <section class="z-40 md:z-60 fixed inset-0">
    <div class="absolute inset-0 -ml-16 opacity-80 bg-true-black" />
    <div class="absolute right-0 inset-y-0 w-full md:w-4/5 max-w-300 bg-white" />
    <div class="absolute inset-0 mt-17 overflow-y-auto">
      <div class="flex min-h-full">
        <div class="absolute inset-0" @click="closeModal" />
        <div
          v-ref="productContainerRef"
          class="product-container ml-auto w-full md:w-4/5 max-w-300 pb-40 sm:pb-20 overflow-hidden bg-dawn"
          @scroll="$event.target.scrollLeft = 0"
        >
          <BaseSpinner v-if="!product" overlay="absolute" />
          <template v-else>
            <button
              type="button"
              class="z-80 fixed -ml-16 w-16 h-16 flex items-center justify-center bg-dawn border-black border-b border-r border-l transition duration-200"
              aria-label="Back to products"
              @click="closeModal"
            >
              <BaseIcon :size="8">
                <IconArrowLeft />
              </BaseIcon>
            </button>

            <div class="flex flex-col lg:flex-row">
              <div class="lg:w-1/2">
                <div
                  v-if="!screen.lg"
                  class="z-90 absolute right-0 top-0 flex justify-end items-center h-14 px-6 bg-white opacity-90 text-gray"
                >
                  <div class="mr-0">
                    <BaseTooltip>
                      <FavoriteToggle
                        :label="product.title"
                        :is-favorited="isFavorited"
                        data-cy="favorite-button"
                        @change="setFavorited"
                      />
                      <template v-slot:tooltip>
                        {{ isFavorited ? 'Remove from favorites' : 'Add to favorites' }}
                      </template>
                    </BaseTooltip>
                  </div>
                </div>

                <!-- Product image slider + gallery -->
                <BaseSliderWithGallery
                  :images="product.images"
                  :alt="product.title"
                  inner-gallery
                />
              </div>

              <div class="static lg:w-1/2 pt-4 md:pt-11">
                <BaseContainer class="static lg:px-15">
                  <div class="flex justify-between mb-4 md:mb-22px">
                    <div>
                      <BaseTag
                        v-for="(tag, index) in sortedTags"
                        :key="index"
                        :label="tag.title"
                        :color="tagColors[tag.slug]"
                        :text-color="tagTextColors[tag.slug]"
                      />
                    </div>
                    <BaseTooltip v-if="screen.lg">
                      <FavoriteToggle
                        :label="product.title"
                        :is-favorited="isFavorited"
                        data-cy="favorite-button"
                        class="-mt-1"
                        @change="setFavorited"
                      />
                      <template v-slot:tooltip>
                        {{ isFavorited ? 'Remove from favorites' : 'Add to favorites' }}
                      </template>
                    </BaseTooltip>
                  </div>

                  <div
                    class="font-bold text-sm uppercase mb-5px"
                    style="line-height: 1.3"
                    data-cy="pdp-brand"
                  >
                    <BaseLink @click="goToBrand">{{ product.brand }}</BaseLink>
                  </div>
                  <h2 class="text-xl" style="line-height: 1.4" data-cy="pdp-title">
                    {{ product.title }}
                  </h2>
                  <div
                    v-if="hasMarketAccess"
                    class="mt-14px md:mt-22px text-lg flex items-center justify-between md:justify-start"
                    data-cy="pdp-price"
                  >
                    <div>
                      <span class="font-semibold text-primary">
                        {{ $formatPrice(selectedVariant.price) }}
                      </span>
                      <s
                        v-if="product.retailValue && product.retailValue > selectedVariant.price"
                        class="ml-1 mr-9px text-gray-opacity68"
                      >
                        {{ $formatPrice(product.retailValue) }}
                      </s>
                    </div>
                    <template v-if="percentOff > 0">
                      <span v-if="screen.md">
                        <BaseTag :label="`${percentOff}% off`" color="primary" />
                      </span>
                      <span v-else class="text-base text-gray-opacity68">
                        Save: {{ $formatPrice(savedAmount) }}
                      </span>
                    </template>
                  </div>

                  <div
                    v-if="variantCanAddRushShipping(product.defaultVariant)"
                    class="pl-3 py-3 my-8 border border-gray-light text-sm md:text-base flex items-center"
                  >
                    <BaseIcon class="inline-block align-middle w-10 mb-1 mr-1">
                      <IconRushShipping />
                    </BaseIcon>
                    <div class="mt-2px">
                      <span> Holiday Delivery Eligible. </span>
                      <BaseLinkStyled
                        color="summer"
                        inline
                        @click="trendingQuestionsModalVisible = true"
                      >
                        See Details
                      </BaseLinkStyled>
                    </div>
                  </div>

                  <BaseRichText class="mt-9" v-html="product.description" />

                  <div
                    class="z-20 fixed inset-x-0 bottom-0 sm:static sm:inset-auto sm:mt-9 px-6 py-4 sm:p-0 border-t border-gray-active sm:border-0 bg-dawn sm:bg-transparent"
                  >
                    <BasePanelWarning v-if="errorMessage" class="mb-4">
                      {{ errorMessage }}
                    </BasePanelWarning>
                    <div class="flex">
                      <div class="mr-4 w-24 flex-shrink-0">
                        <BaseInput
                          v-model="quantity"
                          type="select"
                          data-cy="quantity-dropdown"
                          :options="quantitySelectionOptions"
                          :disabled="!inStock || (inStock && isMaxInCart(selectedVariant.id))"
                        >
                          Qty
                        </BaseInput>
                      </div>
                      <div class="flex-grow">
                        <BaseButton
                          :disabled="
                            addingToCartOrWaitlist || (inStock && isMaxInCart(selectedVariant.id))
                          "
                          @click="inStock ? addToCart() : addToWaitlist()"
                        >
                          <span class="truncate">
                            <span v-if="inStock && isMaxInCart(selectedVariant.id)">
                              Max quantity in cart
                            </span>
                            <span v-else-if="addingToCartOrWaitlist">Adding...</span>
                            <span v-else-if="addedToCartOrWaitlist">Added!</span>
                            <span v-else>{{ inStock ? 'Add to cart' : 'Add to waitlist' }}</span>
                          </span>
                        </BaseButton>
                      </div>
                    </div>
                  </div>

                  <div class="mt-10 border-t-2 border-b-2 border-black">
                    <BaseAccordion button-inner-classes="py-4" data-cy="product-specs-button">
                      <template v-slot:buttonLabel>
                        <span class="font-bold">Details &amp; Care</span>
                      </template>
                      <template v-slot>
                        <div class="pb-5">
                          <BaseRichText data-cy="product-specs" v-html="product.specs" />
                        </div>
                      </template>
                    </BaseAccordion>
                  </div>
                </BaseContainer>
              </div>
            </div>

            <BaseContainer class="lg:px-15">
              <ProductSlider
                v-if="relatedProducts.length"
                :products="relatedProducts"
                context="modal"
                class="mt-18"
              >
                <template v-slot:heading>Members also bought</template>
              </ProductSlider>

              <ProductSlider
                v-if="brandProducts.length"
                :products="brandProducts"
                context="modal"
                class="mt-18"
                data-cy="related-products-slider"
              >
                <template v-if="product.brand === 'Bundle'" v-slot:heading>More bundles</template>
                <template v-else v-slot:heading>More products by {{ product.brand }}</template>
              </ProductSlider>
            </BaseContainer>
          </template>
        </div>
      </div>
    </div>

    <HomeWidgetSingleEntryModal
      v-if="isRushShippingEnabled"
      :open="trendingQuestionsModalVisible"
      :update="{ type: 'trending', slug: 'holiday-delivery' }"
      override-date="<span class='font-medium'>SHIPPING &amp; DELIVERY</span>"
      @dismiss="trendingQuestionsModalVisible = false"
    />
  </section>
</template>
